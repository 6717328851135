function changeFilter({ filter }, { key, value }) {
  return { ...filter, [key]: value };
}

export default function (state, action) {
  switch (action.type) {
    case 'FILTER_BY':
      return {
        ...state,
        filter: changeFilter(state, action.data),
      };
    case 'LOAD_FILTER_FIELDS':
      return {
        ...state,
      };
    case 'LOAD_ADS_DATA':
      return {
        ...state,
        list: action.data,
      };
    case 'LOAD_COMP_DATA':
      return {
        ...state,
        list: action.data,
      };
    case 'LOAD_PAGINATED_DATA':
      return {
        ...state,
        pageIndex: action.pageIndex,
        limit: action.limit,
        totalItems: action.totalItems,
      };
    case 'CHANGE_LIMIT':
      return {
        ...state,
        limit: action.data,
        pageIndex: 1,
      };
    case 'RESET_INDEX':
      return {
        ...state,
        pageIndex: 1,
      };
    case 'SET_OFFSET':
      return {
        ...state,
        offset: action.offset,
      };
    case 'RESET_OFFSET':
      return {
        ...state,
        offset: 0,
      };
    case 'PREV_PAGINATION':
      return {
        ...state,
        pageIndex: state.pageIndex === 0 ? state.pageIndex : state.pageIndex - 1,
      };
    case 'NEXT_PAGINATION':
      return {
        ...state,
        pageIndex: state.pageIndex + 1,
      };
    default:
      return state;
  }
}
