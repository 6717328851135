import useAdsReducer from './reducers/ads';
import useUserReducer from './reducers/user';
import useMediasReducer from './reducers/medias';
import useModalAdsReducer from './reducers/modal.ads';
import useToastAdsReducer from './reducers/toast.ads';
import { useFormAdsReducer, userFormSelectedAdsReducer } from './reducers/form.ads';
import useFlagsReducer from './reducers/flags';
import useMediasFormReducer from './reducers/form.medias';

import { AD_TYPE, STATUS_DEFAULT } from './../config';

export const initialState = {
  user: {
    id: null,
    firstName: null,
    lastName: null,
    username: null,
  },
  medias: [],
  ads: {
    list: [],
    cachedList: [],
    pageIndex: 0,
    offset: 0,
    limit: 0,
    totalItems: 0,
    filter: {
      status: STATUS_DEFAULT,
      states: null,
      boards: null,
      professions: null,
      subjectareas: null,
      title: null,
      course: null,
      medias: null,
    },
  },
  modalAds: {
    as: 'add',
    show: false,
    data: {},
  },
  toastAds: {
    show: false,
    message: '',
  },
  formAds: {
    states: [],
    boards: [],
    professions: [],
    sa: [],
    courses: [],
    offerings: [],
  },
  formAdsSelected: {
    state: null,
    board: null,
    sa: null,
    profession: null,
    id: null,
    img: null,
    type: AD_TYPE,
    status: STATUS_DEFAULT,
    description: '',
    tagline: '',
    title: null,
    course: null,
    provider: null,
    course_cont: null,
    offering: [],
    courseType: null,
    rolling: false,
    medias: [],
    urls: [],
  },
  formMediaSelected: {},
  flags: {
    errors: false,
  },
};

export const mainReducer = (
  { ads, modalAds, formAds, formAdsSelected, user, flags, medias, formMediaSelected, toastAds },
  action
) => ({
  user: useUserReducer(user, action),
  ads: useAdsReducer(ads, action),
  modalAds: useModalAdsReducer(modalAds, action),
  formAds: useFormAdsReducer(formAds, action),
  toastAds: useToastAdsReducer(toastAds, action),
  formAdsSelected: userFormSelectedAdsReducer(formAdsSelected, action),
  flags: useFlagsReducer(flags, action),
  medias: useMediasReducer(medias, action),
  formMediaSelected: useMediasFormReducer(formMediaSelected, action),
});
