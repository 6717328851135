export default function capitalize(names) {
  if (typeof names !== 'string' && names.trim().length === 0) {
    return names;
  }

  return names
    .split(' ')
    .map((name) => name[0].toUpperCase() + name.slice(1).toLowerCase())
    .join(' ');
}
