import { gql } from 'apollo-boost';

export const GET_BOARDS_NAME = gql`
  query getBoardsByState($state: Int) {
    boards(basic_data: { id_cebroker_state: $state, in_status: 1 }) {
      id
      basic_data {
        name
      }
    }
  }
`;

export const GET_BOARD_BY_ID = gql`
  query getBoard($board: Int) {
    boards(id: $board) {
      id
      basic_data {
        name
      }
    }
  }
`;

export const GET_BOARDS = gql`
  query getBoards {
    boards {
      id
      basic_data {
        name
      }
    }
  }
`;
