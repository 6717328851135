export default function (state, action) {
  switch (action.type) {
    case 'OPEN_TOAST':
      return {
        ...state,
        show: true,
        message: action.message,
      };
    case 'CLOSE_TOAST':
      return {
        ...state,
        show: false,
        message: '',
      };
    case 'TOGGLE_TOAST':
      return {
        ...state,
        show: !state.show,
      };
    case 'CHANGE_AS':
      return {
        ...state,
        as: action.as,
      };
    default:
      return state;
  }
}
