import axios from 'axios';
import config, { ERROR_API } from './../../config';
import logger from '@condor-labs/logger';

const { URL_ADS_API, DEFAULT_TOKEN_ADS_API, REQUEST_TIMEOUT } = config;

const adsClient = axios.create({
  baseURL: URL_ADS_API,
  timeout: REQUEST_TIMEOUT,
  headers: {
    Authorization: `Basic ${DEFAULT_TOKEN_ADS_API}`,
  },
});

export const getAds = async () => {
  try {
    const { data } = await adsClient.get('/ad');
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.GET_ADS]: error.stack });
  }
};

export const getClicks = async (idAd, dateAdded = null) => {
  try {
    const { data } = await adsClient.get('/clicks', {
      params: {
        idAd,
        dateAdded,
      },
    });
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.GET_CLICKS]: error.stack });
  }
};

export const getViews = async (idAd, dateAdded = null) => {
  try {
    const { data } = await adsClient.get('/views', {
      params: {
        idAd,
        dateAdded,
      },
    });
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.GET_VIEWS]: error.stack });
  }
};

export const paginate = async (
  pageIndex = 1,
  pageSize = 10,
  {
    status,
    states: stateId,
    boards: boardId,
    professions: professionId,
    subjectareas: subjectAreaId,
    title,
    medias: media,
    course: courseId,
  }
) => {
  let response;

  try {
    response = await adsClient.get(`/ad`, {
      params: {
        pageIndex,
        pageSize,
        status,
        stateId,
        boardId,
        professionId,
        subjectAreaId,
        title,
        media,
        courseId,
      },
    });
    return response.data;
  } catch (error) {
    const { status, data } = error.response;
    if (status === 404) {
      return data;
    }
    logger.log({ [ERROR_API.PAGINATE_AD]: error.stack });
  }
};

export const getAdFiltered = async (professionId, courseId) => {
  let response;

  try {
    response = await adsClient.get(`/ad`, {
      params: {
        professionId,
        courseId,
      },
    });
    return response.data;
  } catch (error) {
    const { status, data } = error.response;
    if (status === 404) {
      return data;
    }
    logger.log({ [ERROR_API.CHECK_AD]: error.stack });
  }
};

export const getMedias = async () => {
  try {
    const { data } = await adsClient.get(`/medias`);
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.GET_MEDIAS]: error.stack });
  }
};

export const getAd = async (_id) => {
  try {
    const { data } = await adsClient.get(`/ad/${_id}`);
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.GET_AD]: error.stack });
  }
};

export const setAd = async (newAd) => {
  try {
    const { data } = await adsClient.post('/ad', newAd);
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.SET_AD]: error.stack });
  }
};

export const updateAd = async (_id, newAd) => {
  try {
    const { data } = await adsClient.put(`/ad/${_id}`, newAd);
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.UPDATE_AD]: error.stack });
  }
};

export const disableAd = async (_id) => {
  try {
    const { data } = await adsClient.put(`/ad/disable/${_id}`);
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.DISABLE_AD]: error.stack });
  }
};

export default adsClient;
