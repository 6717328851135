import { ApolloClient } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import Config from '../../config';

const { URL_SETTINGS_SERVICE_GRAPHQL_API, DEFAULT_TOKEN_GRAPHQL_API } = Config;

const httpLink = createHttpLink({
  uri: URL_SETTINGS_SERVICE_GRAPHQL_API,
});

const authLink = setContext((setter, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: DEFAULT_TOKEN_GRAPHQL_API ? `Basic ${DEFAULT_TOKEN_GRAPHQL_API}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
