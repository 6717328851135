import React from 'react';
import { disableAd } from './../../../api/http/ads';
import { useStateContext } from './../../../store/context';

import { confirmAlert } from 'react-confirm-alert';

import FloatingActionMenuItem from 'emerald-ui/lib/FloatingActionMenuItem';
import Icon from 'emerald-ui/lib/Icon';
import Button from 'emerald-ui/lib/Button';
import Card from 'emerald-ui/lib/Card';

import './styles.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PropTypes from 'prop-types';

function IconActionDropdown({ ad }) {
  const { 1: dispatch } = useStateContext();

  const onDisable = (_id) => async () => {
    await disableAd(_id);
    window.location.reload();
  };

  const showConfirm = (idAd) => () => {
    const onCloseFn = ({ onClose }) => {
      return (
        <Card style={{ width: '400px' }}>
          <Card.Header color="danger">
            <h1 className="card-header-title">Disable ad</h1>
          </Card.Header>
          <div>
            <h1 className="card-title">Are you sure to disable this ad ?</h1>
            <h2 className="card-subtitle">Select &apos;yes&apos; is you want proceed</h2>
            <p>
              <Button shape="flat" color="info" onClick={onDisable(idAd)}>
                Yes
              </Button>
              <Button shape="flat" color="danger" onClick={() => void onClose()}>
                No
              </Button>
            </p>
          </div>
        </Card>
      );
    };
    void confirmAlert({
      customUI: onCloseFn,
    });
  };

  const openModalEdit =
    (as = 'add', ad, data = {}) =>
    (event) => {
      event.stopPropagation();
      if (as === 'edit') {
        dispatch({
          type: 'FORMAT_EXT_DATA',
          data: ad,
        });
      }
      dispatch({
        type: 'OPEN_MODAL',
        data,
        as,
      });
    };

  return (
    <div>
      <div className="floating-action-dropdown dropdown">
        <FloatingActionMenuItem title="Edit" eventKey="1" onClick={openModalEdit('edit', ad)}>
          <Icon name="create" />
        </FloatingActionMenuItem>

        <FloatingActionMenuItem
          title="Disable"
          style={{ marginLeft: '10px' }}
          eventKey="1"
          onClick={showConfirm(ad._id)}
        >
          <Icon name="block" />
        </FloatingActionMenuItem>
      </div>
    </div>
  );
}

IconActionDropdown.propTypes = {
  ad: PropTypes.any,
};

export default React.memo(IconActionDropdown);
