import { formatDataByModel } from './../../utils/format/states';

function pushArray(_, value) {
  const newCont = [...value];
  return newCont;
}

function serializeData({
  adType,
  boardId,
  courseId,
  providerId,
  courseType,
  description,
  img,
  offering,
  medias,
  urls,
  dateAdded,
  professionId,
  stateId,
  status,
  rolling = false,
  tagline,
  title,
  _id,
  subjectAreaId,
}) {
  return {
    state: stateId,
    board: boardId,
    status,
    type: adType,
    course: courseId,
    provider: providerId,
    description,
    medias,
    urls,
    profession: professionId,
    img,
    dateAdded,
    offering,
    rolling,
    tagline,
    courseType,
    title,
    id: _id,
    sa: subjectAreaId || null,
  };
}

export const useFormAdsReducer = function (state, action) {
  switch (action.type) {
    case 'LOAD_DATA':
      return {
        ...state,
        ...action.data,
      };
    case 'LOAD_M_DATA':
      return {
        ...state,
        [action.field]: action.data,
      };

    case 'FORMAT_MODEL_DATA':
      return {
        ...state,
        ...formatDataByModel(action),
      };
    case 'RESET_FORM':
      return {
        ...state,
        boards: [],
        professions: [],
        sa: [],
        courses: [],
        offerings: [],
      };
    default:
      return state;
  }
};

export const userFormSelectedAdsReducer = function (state, action) {
  switch (action.type) {
    case 'SET_OBJECT':
      return {
        ...state,
        ...action.data,
      };
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'SET_FIRST_FIELD_ARRAY':
      return {
        ...state,
        [action.field]: pushArray(state, action.value),
      };
    case 'FORMAT_EXT_DATA':
      return {
        ...state,
        ...serializeData(action.data),
      };
    case 'RESET_FORM_MODAL':
      return {
        state: null,
        board: null,
        sa: null,
        profession: null,
        id: null,
        img: null,
        type: 'course',
        status: 'active',
        description: '',
        title: null,
        courseType: null,
        course: null,
        course_cont: null,
        offering: [],
        rolling: false,
        medias: [],
        urls: [],
      };
    default:
      return state;
  }
};
