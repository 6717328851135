import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { useGqlContext, useStateContext } from './../../store/context';
import { getOfferings } from './../../api/http';
import { getSettings } from './../../api/http/spotlight';
import { setAd, updateAd, getAdFiltered, getViews, getClicks } from './../../api/http/ads';

import { GET_STATE_BY_ID, GET_STATE_ACTIVE } from './../../api/graphql/states';
import { GET_BOARDS_NAME, GET_BOARD_BY_ID } from './../../api/graphql/boards';
import { GET_PROFESSION_NAME, GET_PROFESSION_BY_ID } from './../../api/graphql/professions';
import { GET_SUBJECTAREA_NAME, GET_SUBJECTAREA_BY_ID } from './../../api/graphql/subjectarea';

import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';

import Modal from 'emerald-ui/lib/Modal';
import Button from 'emerald-ui/lib/Button';
import SingleSelect from 'emerald-ui/lib/SingleSelect';
import SearchableSelect from 'emerald-ui/lib/SearchableSelect';
import TextField from 'emerald-ui/lib/TextField';
import Checkbox from 'emerald-ui/lib/Checkbox';
import Chip from 'emerald-ui/lib/Chip';
import FileUpload from 'emerald-ui/lib/FileUpload';
import FileUploadItem from 'emerald-ui/lib/FileUploadItem';
import Spinner from 'emerald-ui/lib/Spinner';

import Medias from './../Medias';

import { isNullOrUndefined } from 'util';
import validate from './../../utils/validate';
import Config, { STATUS_DEFAULT, STATUS_INACTIVE } from './../../config';

import './styles.css';
import PropTypes from 'prop-types';
import logger from '@condor-labs/logger';

const { STORAGE_API } = Config;

async function useReqGql(client, query, variables = {}) {
  const { data } = await client.query({ query, variables });
  return data;
}

function ModalAds(params) {
  const { as, staticContext, onHide: onCloseModal, ...rest } = params;
  if (typeof staticContext !== 'undefined') {
    logger.log('staticContext');
  }

  const { client } = useGqlContext();

  const [
    {
      formAds: { states, boards, professions, courses },
      formAdsSelected,
      flags,
    },
    dispatch,
  ] = useStateContext();

  const { 1: setDescription } = useState('');
  const [taglineForm, setTagline] = useState('');
  const [progress, setProgress] = useState(0);
  const [speed, setSpeed] = useState('');
  const [stateViews, setStateViews] = useState('');
  const [stateClicks, setStateClicks] = useState('');
  const [viewSpotlightCE, setViewSpotlightCE] = useState(false);

  const fitNumber = (total) => {
    let totalFitNumber = total;
    if (total > 1000000) {
      totalFitNumber = `${(total / 1000000).toFixed(2)}M`;
    } else if (total > 1000) {
      totalFitNumber = `${(total / 1000).toFixed(2)}K`;
    }

    return totalFitNumber;
  };

  useEffect(() => {
    (async () => {
      if (as === 'edit') {
        const { state, board, profession, sa, course, id } = formAdsSelected;

        const stateAux = useReqGql(client, GET_STATE_BY_ID, { state });
        const boardAux = useReqGql(client, GET_BOARD_BY_ID, { board });
        const professionAux = useReqGql(client, GET_PROFESSION_BY_ID, {
          profession,
        });
        const saAux = sa ? useReqGql(client, GET_SUBJECTAREA_BY_ID, { sa }) : [];
        const courseAux = getOfferings(profession, sa, course);
        const [states = [], sas = [], boards = [], professions = [], { items: courses = [] }] = await Promise.all([
          stateAux,
          saAux,
          boardAux,
          professionAux,
          courseAux,
        ]);

        dispatch({
          type: 'LOAD_DATA',
          data: { courses },
        });

        dispatch({
          type: 'LOAD_DATA',
          data: states,
        });

        if (sas && sas.length > 0) {
          dispatch({
            type: 'LOAD_M_DATA',
            field: 'sa',
            data: sas,
          });
        }

        dispatch({
          type: 'LOAD_DATA',
          data: boards,
        });

        dispatch({
          type: 'LOAD_DATA',
          data: professions,
        });

        setSpotlightConfig(professions);

        let { total: TotalViews } = await getViews(id);
        TotalViews = fitNumber(TotalViews);
        setStateViews(TotalViews);

        let { total: TotalClicks } = await getClicks(id);
        TotalClicks = fitNumber(TotalClicks);
        setStateClicks(TotalClicks);
      } else {
        const { boards: stateBoards } = await useReqGql(client, GET_STATE_ACTIVE);

        dispatch({
          type: 'FORMAT_MODEL_DATA',
          field: 'states',
          data: stateBoards,
        });
      }
    })();
  }, [as]);

  useEffect(() => {
    const defaultData = formAdsSelected.course_cont && formAdsSelected.course_cont.description;

    setDescription(defaultData);

    dispatch({
      type: 'SET_FIELD',
      field: 'title',
      value: formAdsSelected.course_cont ? formAdsSelected.course_cont.name : formAdsSelected.title,
    });
  }, [formAdsSelected.course_cont]);

  useEffect(() => {
    const defaultData = formAdsSelected.tagline || '';
    setTagline(defaultData);
  }, [formAdsSelected.tagline]);

  useEffect(() => {
    (async () => {
      const { profession, course, offering } = formAdsSelected;

      if (profession && course && offering.length && as === 'add') {
        let { items: adsSelected } = await getAdFiltered(profession, course);

        adsSelected = adsSelected.slice(0, 1);

        if (adsSelected.length) {
          adsSelected[0].offering = offering;

          dispatch({
            type: 'FORMAT_EXT_DATA',
            data: adsSelected[0],
          });

          dispatch({
            type: 'CHANGE_AS',
            as: 'edit',
          });
        }
      }
    })();
  }, [formAdsSelected.profession, formAdsSelected.course, formAdsSelected.offering, as]);

  const bundleStatus = {
    add: {
      title: 'Add Ad',
      button: 'SUBMIT AD',
      altProps: {
        disabled: false,
      },
    },
    edit: {
      title: 'Edit Ad',
      button: 'UPDATE AD',
      altProps: {
        disabled: true,
      },
    },
  };

  const { title, button, altProps } = bundleStatus[as];

  const deserializeData = ({
    state,
    board,
    status,
    type,
    course,
    provider,
    description,
    img,
    medias,
    profession,
    rolling,
    offering = [],
    tagline,
    courseType,
    title,
    urls,
    id,
    sa,
  }) => {
    return {
      stateId: state,
      boardId: board,
      courseId: course,
      providerId: provider,
      status,
      description,
      img,
      offering,
      medias,
      rolling,
      courseType,
      professionId: profession,
      adType: type,
      tagline,
      title,
      urls,
      _id: id,
      subjectAreaId: sa,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // formAdsSelected.description = descriptionForm;
    formAdsSelected.tagline = taglineForm;
    const finalData = deserializeData(formAdsSelected);

    const { stateId, boardId, courseId, description, img, professionId, tagline, medias } = finalData;

    const someFieldNotValidated = validate(stateId, boardId, courseId, description, img, professionId, tagline);

    dispatch({
      type: 'VALIDATE',
    });

    if (!medias.length) {
      dispatch({
        type: 'OPEN_TOAST',
        message: 'You should get at least one media',
      });
      return;
    }

    const havePriorities = medias.every(({ priority }) => Boolean(priority));

    if (!havePriorities) {
      dispatch({
        type: 'OPEN_TOAST',
        message: 'The priority must be assigned or it could not be 0',
      });
      return;
    }

    if (!someFieldNotValidated) {
      if (!finalData.subjectAreaId) {
        delete finalData.subjectAreaId;
      }

      let ad = null;

      if (as === 'add') {
        delete finalData._id;
        delete finalData.urls;
        finalData.offering = _.map(finalData.offering, o => _.omit(o, ['course.ratings']));

        ad = await setAd({ ...finalData });
        if (ad) {
          window.location.reload();
        }
      } else {
        if (finalData.status === STATUS_INACTIVE) {
          finalData.status = STATUS_DEFAULT;
        }

        const { _id, description, tagline, medias, img, rolling, status, offering } = finalData;

        const _offering = _.map(offering, o => _.omit(o, ['course.ratings']));

        ad = await updateAd(_id, {
          description,
          tagline,
          medias,
          img,
          rolling,
          status,
          offering: _offering,
        });
        if (ad) {
          window.location.reload();
        }
      }
    }
  };

  const onUploadSuccess = ({ target }) => {
    if (target.status === 200) {
      let { response } = target;
      const { responseURL } = target;
      response = response.replace(/"/g, '');
      const img = `${responseURL}${response}`;

      dispatch({
        type: 'SET_FIELD',
        field: 'img',
        value: img,
      });
    }
  };

  const onUploadStart = ([{ progress }]) => {
    setProgress(progress);
  };

  const onChangeDescription = (e) => {
    const { value } = e.target;
    dispatch({
      type: 'SET_FIELD',
      field: 'description',
      value,
    });
  };

  const onChangeTagline = (e) => {
    const { value } = e.target;
    dispatch({
      type: 'SET_FIELD',
      field: 'tagline',
      value,
    });
  };

  const setSpotlightConfig = async (data = null) => {
    const payload = [];

    if (typeof data === 'number') {
      payload.push(data);
    }

    if (typeof data === 'object') {
      const { professions = {} } = data;
      const { id } = professions && professions[professions.length - 1];
      payload.push(id);
    }

    const response = await getSettings({ professions: payload });

    if (response) {
      const { settings } = response;
      const { isAbleToViewSpotlightCE, isAbleToViewDiscoverTab } = settings && settings[settings.length - 1];
      setViewSpotlightCE(isAbleToViewDiscoverTab && isAbleToViewSpotlightCE);
    }
  };

  // Serialize data
  const serializeValue =
    (field, next_query = null) =>
    async (value) => {
      switch (field) {
        case 'state':
          dispatch({
            type: 'LOAD_DATA',
            data: {
              boards: [],
              professions: [],
              sa: [],
              courses: [],
              offerings: [],
            },
          });

          dispatch({
            type: 'SET_OBJECT',
            data: {
              board: null,
              sa: null,
              profession: null,
              tagline: '',
              title: null,
              courseType: '',
              course: null,
              course_cont: null,
              offering: [],
            },
          });
          break;
        case 'board':
          dispatch({
            type: 'LOAD_DATA',
            data: {
              professions: [],
              sa: [],
              courses: [],
              offerings: [],
            },
          });

          dispatch({
            type: 'SET_OBJECT',
            data: {
              sa: null,
              profession: null,
              title: null,
              course: null,
              courseType: '',
              tagline: '',
              course_cont: null,
              offering: [],
            },
          });

          break;
        case 'sa':
          dispatch({
            type: 'LOAD_DATA',
            data: {
              courses: [],
              offerings: [],
            },
          });

          dispatch({
            type: 'SET_OBJECT',
            data: {
              title: null,
              course: null,
              courseType: '',
              tagline: '',
              course_cont: null,
              offering: [],
            },
          });

          break;

        default:
          break;
      }

      if (field === 'sa') {
        const { items: courses } = await getOfferings(formAdsSelected.profession, value);

        dispatch({
          type: 'LOAD_DATA',
          data: { courses },
        });
      }

      dispatch({
        type: 'SET_FIELD',
        field,
        value,
      });

      if (!isNullOrUndefined(next_query)) {
        const data = await useReqGql(client, next_query, { [field]: value });

        dispatch({
          type: 'LOAD_DATA',
          data,
        });
      }
    };

  const serializeProfessionValue =
    (field, next_query = null) =>
    async (value) => {
      const auxData = await useReqGql(client, next_query, { [field]: value });

      const [data] = auxData['professions'];

      dispatch({
        type: 'LOAD_M_DATA',
        field: 'sa',
        data: data.subjectarea,
      });

      if (field === 'profession') {
        dispatch({
          type: 'LOAD_DATA',
          data: {
            courses: [],
          },
        });

        dispatch({
          type: 'SET_OBJECT',
          data: {
            title: null,
            sa: null,
            tagline: '',
            courseType: '',
            course: null,
            course_cont: null,
            offering: {},
          },
        });

        const { items: courses } = await getOfferings(value);

        dispatch({
          type: 'LOAD_DATA',
          data: { courses },
        });
      }

      dispatch({
        type: 'SET_FIELD',
        field,
        value,
      });

      setSpotlightConfig(value);
    };
  const getOfferingsBy = (value = null) => {
    return getOfferings(formAdsSelected.profession, formAdsSelected.sa, value);
  };

  const onInputChange = _.debounce(async (value) => {
    if (!value) {
      return;
    }

    const courses = { data: { items: [] }, searchable: true };

    if (value && formAdsSelected.profession) {
      const regIfCourse = new RegExp(/^20-/, 'gi');

      const codeCourseVal = regIfCourse.test(value);

      if (codeCourseVal) {
        let { 1: courseId } = value.split('-');

        courseId = parseInt(courseId, 10);

        if (Number.isInteger(courseId)) {
          courses.data = await getOfferingsBy(courseId);

          courses.data.items = courses.data.items.filter(({ course }) => courseId === course.id);
        } else if (Number.isNaN(courseId)) {
          courses.searchable = false;
        }
      } else if (!codeCourseVal && Number.isNaN(parseInt(value))) {
        courses.data = await getOfferingsBy(value);
      } else {
        courses.searchable = false;
      }
    } else if (!value) {
      courses.data = await getOfferingsBy();
    }

    if (!courses.searchable) {
      return;
    }

    dispatch({
      type: 'LOAD_DATA',
      data: { courses: courses.data.items },
    });
  }, 300);

  const serializeCourse = () => async (ids) => {
    if (typeof ids === 'string') {
      const [courseId, offeringId] = ids.split('-').map(Number);

      const offeringSelected = courses.find(({ id, course }) => id === offeringId && course.id === courseId);
      if (offeringSelected) {
        const { items: courses } = await getOfferingsBy(offeringSelected.course.id);

        dispatch({
          type: 'LOAD_DATA',
          data: { courses },
        });

        dispatch({
          type: 'SET_FIELD',
          field: 'course',
          value: offeringSelected.course.id,
        });

        dispatch({
          type: 'SET_FIELD',
          field: 'provider',
          value: offeringSelected.course.provider.id,
        });

        dispatch({
          type: 'SET_FIELD',
          field: 'course_cont',
          value: offeringSelected.course,
        });

        dispatch({
          type: 'SET_FIELD',
          field: 'courseType',
          value: offeringSelected.course.type,
        });

        dispatch({
          type: 'SET_FIELD',
          field: 'description',
          value: offeringSelected.course.description,
        });

        dispatch({
          type: 'SET_FIELD',
          field: 'offering',
          value: [offeringSelected],
        });

        dispatch({
          type: 'SET_FIELD',
          field: 'rolling',
          value: false,
        });
      }
    }
  };

  const onChangeRolling = (e) => {
    const { courseType } = formAdsSelected;

    dispatch({
      type: 'SET_FIELD',
      field: 'rolling',
      value: courseType === 'CD_LIVE' ? Boolean(e.target.checked) : false,
    });
  };

  const onCancelFile = () => {
    dispatch({
      type: 'SET_FIELD',
      field: 'img',
      value: null,
    });
    setProgress(0);
  };

  const onUploadProgress = ({ speed, progress }) => {
    setProgress(progress);
    setSpeed(speed);
  };

  // Render Lists
  const renderBasicList = (field) => {
    const renderBasicListFn = ({ id, basic_data: { name } }, index) => {
      return (
        <option key={index} value={id} selected={`${id}` === `${formAdsSelected[field]}`}>
          {name}
        </option>
      );
    };
    renderBasicListFn.propTypes = {
      id: PropTypes.any,
      basic_data: PropTypes.any,
    };
    return renderBasicListFn;
  };

  const sortByName = ({ basic_data: { name: nameA } }, { basic_data: { name: nameB } }) => {
    return nameA.localeCompare(nameB);
  };

  const renderCourseList = ({ id, course, endDate, startDate }) => {
    const isSelected = formAdsSelected.course && formAdsSelected.course === course.id;
    return (
      <option
        key={`${course.id.toString()}-${id.toString()}`}
        value={`${course.id.toString()}-${id.toString()}-20-${course.id.toString()}`}
        selected={isSelected}
        captionText={course.name}
      >
        <div className="course-details">
          <span className="serial-course">{`20-${course.id}`}</span>
          <strong>{course.type === 'CD_ANYTIME' ? 'Anytime' : `${startDate} - ${endDate}`}</strong>
          <span> {course.name} </span>
        </div>
      </option>
    );
  };

  function onCloseModalForm() {
    setStateViews(null);
    setStateClicks(null);
    onCloseModal();
  }

  return (
    <Modal hideOnOutsideClick onHide={onCloseModalForm} className="ad-modal" {...rest}>
      <Modal.Header closeButton>
        <Modal.Title className="title-modal-items">
          {title}
          <span>
            {as === 'edit' ? (
              <div className="metrics-container">
                {Number.isInteger(stateClicks) && Number.isInteger(stateViews) ? (
                  <div>
                    - <span> Views: </span>
                    <strong>{stateViews}</strong> · <span> Clicks: </span> <strong>{stateClicks}</strong>
                  </div>
                ) : (
                  <Spinner size="sm" color="info" />
                )}
              </div>
            ) : null}
          </span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit} className="form-modal-wrapper">
        <Modal.Body className="clear-top">
          <div className="modal-form">
            <div className="general-form form">
              <h3
                style={{
                  fontWeight: 'bold',
                  marginTop: '0px',
                  fontFamily: 'inherit',
                }}
              >
                General *
              </h3>
              <Row className="general-fields">
                <Col md={12}>
                  <SingleSelect
                    label="State *"
                    id="state"
                    placeholder="States..."
                    errorMessage={flags.errors && !formAdsSelected.state ? 'This item is required' : null}
                    onSelect={serializeValue('state', GET_BOARDS_NAME)}
                    {...altProps}
                    className="select-wrapper"
                  >
                    {states.sort(sortByName).map(renderBasicList('state'))}
                  </SingleSelect>
                </Col>
                <Col md={12}>
                  <SingleSelect
                    label="Board *"
                    placeholder="Boards..."
                    errorMessage={flags.errors && !formAdsSelected.board ? 'This item is required' : null}
                    id="board"
                    onSelect={serializeValue('board', GET_PROFESSION_NAME)}
                    disabled={altProps.disabled || boards.length === 0}
                    className="base-size-items select-wrapper"
                  >
                    {boards.sort(sortByName).map(renderBasicList('board'))}
                  </SingleSelect>
                </Col>
                <Col md={12}>
                  <SingleSelect
                    label="Profession *"
                    placeholder="Professions..."
                    id="profession"
                    errorMessage={flags.errors && !formAdsSelected.profession ? 'This item is required' : null}
                    disabled={altProps.disabled || professions.length === 0}
                    onSelect={serializeProfessionValue('profession', GET_SUBJECTAREA_NAME)}
                    className="select-wrapper"
                  >
                    {professions.sort(sortByName).map(renderBasicList('profession'))}
                  </SingleSelect>
                </Col>
                <Col md={12}>
                  <SearchableSelect
                    label="Course *"
                    name="course"
                    errorMessage={flags.errors && !formAdsSelected.course ? 'This item is required' : null}
                    autoComplete="on"
                    placeholder="Search course..."
                    id="course"
                    filterByValue
                    onSelect={serializeCourse('course')}
                    disabled={altProps.disabled || !formAdsSelected.profession}
                    onInputChange={onInputChange}
                    className="base-size-items select-wrapper"
                  >
                    {courses.map(renderCourseList)}
                  </SearchableSelect>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <TextField
                    label="Tagline *"
                    placeholder="Type a tagline..."
                    id="tagline"
                    errorMessage={flags.errors && !formAdsSelected.tagline ? 'This item is required' : null}
                    value={formAdsSelected.tagline || ''}
                    onChange={onChangeTagline}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div
                    className={`form-group text-field-alt ${
                      flags.errors && !formAdsSelected.description ? 'has-error' : ''
                    }`}
                  >
                    <label htmlFor="comment" className="select-label control-label">
                      Description *
                    </label>
                    <textarea
                      className="form-control txtarea"
                      placeholder="Type a description..."
                      rows="5"
                      id="comment"
                      value={formAdsSelected.description || ''}
                      onChange={onChangeDescription}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div
                    className={`form-group text-field-alt ${flags.errors && !formAdsSelected.img ? 'has-error' : ''}`}
                  >
                    <label htmlFor="comment" className="text-field-alt control-label">
                      Image *{' '}
                    </label>
                    {formAdsSelected.img || progress > 0 ? (
                      <FileUploadItem
                        name={`${formAdsSelected.img ? formAdsSelected.img.split('/').pop() : 'Loading...'}`}
                        cancelable
                        onCancel={onCancelFile}
                        progress={progress}
                        speed={speed}
                      />
                    ) : (
                      <FileUpload
                        className={flags.errors && !formAdsSelected.img ? 'has-error' : ''}
                        url={STORAGE_API}
                        onUploadStart={onUploadStart}
                        onUploadSuccess={onUploadSuccess}
                        onProgress={onUploadProgress}
                        uploadListCondensed
                      />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '10px',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Chip>
                        Recommended image dimensions * <strong> (327 x 112)</strong>
                      </Chip>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {formAdsSelected.profession && formAdsSelected.course ? (
              <React.Fragment>
                <hr />
                <div className="media-form form">
                  <h3
                    style={{
                      fontWeight: 'bold',
                      marginTop: '0px',
                      marginBottom: '25px',
                    }}
                  >
                    Set media and priority *
                  </h3>
                  <Medias asStatus={as} viewSpotlight={viewSpotlightCE} />
                </div>
              </React.Fragment>
            ) : null}
            {formAdsSelected.courseType && formAdsSelected.courseType === 'CD_LIVE' && (
              <div>
                <Row>
                  <Col md={12}>
                    <React.Fragment>
                      <hr />
                      <Checkbox
                        label="Enable Rolling"
                        checked={formAdsSelected.rolling || false}
                        onChange={onChangeRolling}
                      />
                    </React.Fragment>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-chip">
            <Chip>Required Fields (*)</Chip>
          </div>
          <div className="btn-side-container">
            <Button onClick={onCloseModalForm} shape="flat" color="info">
              CANCEL
            </Button>
            <Button color="info" type="submit">
              {button}
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

ModalAds.propTypes = {
  params: PropTypes.any,
};

export default withRouter(ModalAds);
