import { gql } from 'apollo-boost';

export const GET_PROFESSION_NAME = gql`
  query getProfessionsByBoard($board: Int) {
    professions(basic_data: { id_board: $board, in_active: 1 }) {
      id
      basic_data {
        name
      }
    }
  }
`;

export const GET_PROFESSION_BY_ID = gql`
  query getProfession($profession: Int) {
    professions(id: $profession) {
      id
      basic_data {
        name
      }
    }
  }
`;

export const GET_PROFESSIONS = gql`
  query getProfessions {
    professions {
      id
      basic_data {
        name
      }
    }
  }
`;
