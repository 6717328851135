import { gql } from 'apollo-boost';

export const GET_STATE_BY_ID = gql`
  query GetState($state: Int) {
    states(id: $state) {
      id
      basic_data {
        name
      }
    }
  }
`;

export const GET_STATE_ACTIVE = gql`
  query GetStatesActive {
    boards(basic_data: { in_status: 1 }) {
      basic_data {
        id_cebroker_state
        state {
          id
          basic_data {
            name
          }
        }
      }
    }
  }
`;

export const GET_STATES_NAME = gql`
  query GetStatesName {
    states {
      id
      basic_data {
        name
      }
    }
  }
`;
