import React from 'react';

// FIXME: Trying out FAB (add ads)
import IconButton from 'emerald-ui/lib/IconButton';

import './styles.css';
import PropTypes from 'prop-types';

function GlobalFab({ openModal, ...rest }) {
  return (
    <React.Fragment>
      <IconButton icon="add" title="Add an Ad" onClick={openModal()} {...rest} />
    </React.Fragment>
  );
}

GlobalFab.propTypes = {
  openModal: PropTypes.any,
};

export default GlobalFab;
