export default function (state, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        show: true,
        as: action.as,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        show: false,
        as: 'add',
      };
    case 'TOGGLE_MODAL':
      return {
        ...state,
        show: !state.show,
      };
    case 'CHANGE_AS':
      return {
        ...state,
        as: action.as,
      };
    default:
      return state;
  }
}
