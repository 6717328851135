import React, { createContext, useReducer, useContext } from 'react';

import { ApolloContext } from 'react-apollo';

import PropTypes from 'prop-types';

export const MainContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <MainContext.Provider value={useReducer(reducer, initialState)}>{children}</MainContext.Provider>
);

StateProvider.propTypes = {
  reducer: PropTypes.any,
  initialState: PropTypes.any,
  children: PropTypes.any,
};

export const useStateContext = () => useContext(MainContext);

export const useGqlContext = () => useContext(ApolloContext);
