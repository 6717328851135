import React, { useEffect, useState } from 'react';

import Container from 'emerald-ui/lib/Container';
import Navbar from 'emerald-ui/lib/Navbar';
import Nav from 'emerald-ui/lib/Nav';
import DropdownItem from 'emerald-ui/lib/DropdownItem';
import Avatar from 'emerald-ui/lib/Avatar';
import DropdownButton from 'emerald-ui/lib/DropdownButton';

import BrandLogo from './../common/Brand';

import acronym from './../../utils/acronym';
import goTo from './../../utils/goto';

import Config from './../../config';

import './styles.css';
import PropTypes from 'prop-types';

const { SECURE_URL, LAUNCHPAD_URL } = Config;

function Appbar({ user }) {
  const [currUser, setCurrUser] = useState();

  useEffect(() => {
    if (user) {
      user.avatar = acronym(user.firstName, user.lastName);
      setCurrUser(user);
    }
  }, [user]);

  const handleGoTo = (url) => () => {
    if (url) {
      goTo(url);
    }
  };

  return (
    <Navbar className="appbar" theme="light" breakAt="sm">
      <Container>
        <div className="nav-container">
          <Navbar.Brand>
            <a href="/">
              <BrandLogo />
            </a>
          </Navbar.Brand>
          <Nav>
            <DropdownButton title={<Avatar title={currUser && currUser.avatar} />} fromRight noCaret>
              <DropdownItem eventKey="1" onClick={handleGoTo(SECURE_URL)}>
                Back to Home
              </DropdownItem>
              <DropdownItem separator />
              <DropdownItem eventKey="4" onClick={handleGoTo(`${LAUNCHPAD_URL}/logout`)}>
                Log Out
              </DropdownItem>
            </DropdownButton>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
}

Appbar.propTypes = {
  user: PropTypes.any,
};

export default React.memo(Appbar);
