import axios from 'axios';
import config, { ERROR_API } from '../../config';
import logger from '@condor-labs/logger';

const { LICENSEE_INTERNAL_API, DEFAULT_TOKEN_ADS_API, REQUEST_TIMEOUT } = config;

const spotlightClient = axios.create({
  baseURL: LICENSEE_INTERNAL_API,
  timeout: REQUEST_TIMEOUT,
  headers: {
    Authorization: `Basic ${DEFAULT_TOKEN_ADS_API}`,
  },
});

export const getSettings = async ({ professions }) => {
  try {
    const { data } = await spotlightClient.get(`/discover/settings`, {
      params: { professions },
    });
    return data;
  } catch (error) {
    logger.log({ [ERROR_API.GET_MEDIAS]: error.stack });
  }
};
