import React from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/lib/Grid';

import './styles.css';

const Wrapper = ({ children }) => (
  <div className="wrapper">
    <Container className="spaces-top">{children}</Container>
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Wrapper;
