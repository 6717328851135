import axios from 'axios';
import config, { ERROR_API } from './../../config';
import logger from '@condor-labs/logger';

const { URL_COURSES_API, URL_COURSES_OFFERING_API } = config;

export const getCourses = async (professionId = '', pageSize = 10) => {
  try {
    const { data } = await axios(`${URL_COURSES_API}/courses`, {
      params: {
        professionId,
        pageSize,
      },
    });
    return data;
  } catch (error) {
    logger.error({ [ERROR_API.COURSES_API]: error.stack });
  }
};

export const getOfferings = async (
  profession = '',
  subjectArea = null,
  term = '',
  expand = 'totalItems',
  sortField = null,
  courseType = '',
  pageSize = 10
) => {
  try {
    const { data } = await axios(`${URL_COURSES_OFFERING_API}/offerings`, {
      params: {
        profession,
        subjectArea,
        term,
        expand,
        sortField,
        courseType,
        pageSize,
      },
    });

    return data;
  } catch (error) {
    logger.error({ [ERROR_API.OFFERING_DATE_API]: error.stack });
  }
};
