import React, { useEffect, useState } from 'react';
import { useStateContext } from './../../store/context';

import Checkbox from 'emerald-ui/lib/Checkbox';
import TextField from 'emerald-ui/lib/TextField';
import Alert from 'emerald-ui/lib/Alert';

import { simpleFormat } from './../../utils/dateFormat';

import { GLOBAL_MEDIAS_SA } from './../../config';

import './styles.css';
import PropTypes from 'prop-types';

const getAlt = ({ code, findableWhenNoSubjectArea }) => (!findableWhenNoSubjectArea ? `${code} SA` : code);

const includeMedias = (myMedia = [], altMedias) => {
  const mediaSelected = myMedia.filter((media) => {
    if (media && media._id) {
      const { code, findableWhenNoSubjectArea } = media;

      const myAltCode = getAlt({ code, findableWhenNoSubjectArea });

      return Object.keys(altMedias).includes(myAltCode);
    }

    return false;
  });

  const objPrMedia = mediaSelected.reduce((acc, mediaSel) => {
    const { code, findableWhenNoSubjectArea, dateAdded, subjectAreas, priority } = mediaSel;

    const altCode = getAlt({ findableWhenNoSubjectArea, code });

    const data = acc[altCode];

    if (!data.status) {
      data.status = true;
    }

    if (!findableWhenNoSubjectArea) {
      const refs = subjectAreas.map((xref) => ({ xref, priority, dateAdded }));
      data.refs.push(...refs);
    } else {
      data.dateAdded = dateAdded;
      data.priority = priority;
    }

    return { ...acc, [altCode]: { ...data } };
  }, altMedias);

  return objPrMedia;
};

const includeSubjectAreas = (altMedias) => {
  return Object.values(altMedias)
    .filter(({ findableWhenNoSubjectArea }) => !findableWhenNoSubjectArea)
    .reduce((acc, { refs, altCode }) => ({ ...acc, [altCode]: refs }), {});
};

function prepareObj(arr) {
  return (defaultValue) => {
    return arr.reduce((acc, media) => {
      const altCode = getAlt(media);

      const res = {
        ...acc,
        [altCode]: { ...defaultValue, ...media, altCode, refs: [] },
      };

      return res;
    }, {});
  };
}

const MediaItem = ({
  item,
  index,
  statusPriority,
  setStatusPriority,
  codeTyping,
  setCodeTyping,
  sa,
  saSelected,
  setSaSelected,
}) => {
  let { code } = item;
  const { findableWhenNoSubjectArea } = item;

  code += !findableWhenNoSubjectArea ? ' SA' : '';

  const onChangeStatus =
    (code, key) =>
    ({ target }) => {
      const { value, checked } = target;

      let data = key === 'status' ? checked : value;

      const preSaveCode = statusPriority[code];

      if (key === 'priority' && !Number.isInteger(Number(value))) {
        return;
      } else if (key === 'priority' && value) {
        data = Number(value);
      }

      if (key === 'status' && !checked) {
        preSaveCode.priority = 0;
        preSaveCode.dateAdded = null;
        preSaveCode.refs = [];
        setSaSelected({ ...saSelected, [code]: [] });
      }

      setCodeTyping(code);
      setStatusPriority({
        ...statusPriority,
        [code]: { ...preSaveCode, [key]: data },
      });
    };

  const onSelectSa =
    (code, xref) =>
    ({ target }) => {
      const { checked } = target;

      const newSaSelected = { ...saSelected };

      if (checked) {
        newSaSelected[code] = newSaSelected[code] || [];
        newSaSelected[code].push({ xref, priority: 0 });
      } else {
        const existThisId = newSaSelected[code].findIndex((item) => item && item.xref === xref);

        if (existThisId >= 0) {
          newSaSelected[code].splice(existThisId, 1);
        }
      }
      setSaSelected({ ...newSaSelected });
    };

  const onChangeSa =
    (code, xref) =>
    ({ target }) => {
      const { value } = target;

      const newSaSelected = { ...saSelected };

      if (!Number.isInteger(Number(value))) {
        return;
      }

      const existThisId = newSaSelected[code].findIndex((item) => item && item.xref === xref);

      if (existThisId >= 0) {
        const { dateAdded } = newSaSelected[code][existThisId];
        newSaSelected[code].splice(existThisId, 1, {
          xref,
          priority: Number(value),
          dateAdded,
        });
      }

      setCodeTyping(`${code}_${xref}`);
      setSaSelected({ ...newSaSelected });
    };

  const resetFocus = () => {
    setCodeTyping(null);
  };

  return (
    <div className={`form-media ${!findableWhenNoSubjectArea ? 'subjectareas' : ''}`}>
      <Checkbox
        label={code}
        onChange={onChangeStatus(code, 'status')}
        checked={statusPriority[code] && statusPriority[code].status ? statusPriority[code].status : false}
      />
      {findableWhenNoSubjectArea ? (
        <div className="format-input">
          <TextField
            disabled={statusPriority[code] && !statusPriority[code].status}
            fromRight
            autoFocus={codeTyping === code}
            onChange={onChangeStatus(code, 'priority')}
            value={statusPriority[code] && statusPriority[code].priority ? statusPriority[code].priority : ''}
            key={`${code}_${index}`}
            onBlur={resetFocus}
          />
          <span className="date-input">
            {statusPriority[code].dateAdded && (
              <React.Fragment>
                <strong>Added: </strong> {simpleFormat(statusPriority[code])}
              </React.Fragment>
            )}
          </span>
        </div>
      ) : (
        statusPriority[code] &&
        statusPriority[code].status && (
          <div className="subjectareas-wrapper">
            {sa.length ? (
              sa.map(({ name, xref }, index) => {
                const existProp = saSelected[code] && saSelected[code].some((item) => item.xref === xref);

                const refSelected = saSelected[code] && saSelected[code].find((item) => item.xref === xref);
                return (
                  <React.Fragment key={index}>
                    <div className="sub-subjectarea-wrapper">
                      <Checkbox
                        label={name}
                        onChange={onSelectSa(code, xref)}
                        checked={sa.some(
                          ({ xref: mxRef }) => saSelected[code] && saSelected[code].some((item) => item.xref === mxRef)
                        )}
                      />
                      <div className="format-input">
                        <TextField
                          disabled={
                            !sa.some(
                              ({ xref: mxRef }) =>
                                saSelected[code] && saSelected[code].some((item) => item.xref === mxRef)
                            )
                          }
                          fromRight
                          onChange={onChangeSa(code, xref)}
                          autoFocus={codeTyping === `${code}_${xref}`}
                          onBlur={resetFocus}
                          value={existProp ? refSelected.priority : ''}
                        />
                        <span className="date-input">
                          {existProp && refSelected.dateAdded ? (
                            <React.Fragment>
                              <strong>Added: </strong>
                              {simpleFormat(refSelected)}
                            </React.Fragment>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <p>No SA Founds</p>
            )}
          </div>
        )
      )}
    </div>
  );
};

MediaItem.propTypes = {
  item: PropTypes.any,
  index: PropTypes.any,
  statusPriority: PropTypes.any,
  setStatusPriority: PropTypes.any,
  codeTyping: PropTypes.any,
  setCodeTyping: PropTypes.any,
  sa: PropTypes.any,
  saSelected: PropTypes.any,
  setSaSelected: PropTypes.any,
};

const Medias = ({ viewSpotlight }) => {
  const [
    {
      medias,
      formAdsSelected,
      modalAds: { as },
    },
    dispatch,
  ] = useStateContext();

  const [mediasList, setMediasList] = useState([]);
  const [statusPriority, setStatusPriority] = useState({});
  const [codeTyping, setCodeTyping] = useState('');
  const [sa, setSa] = useState([]);
  const [saSelected, setSaSelected] = useState({});

  const initialData = () => {
    const main = [...medias];
    const { medias: myMedias } = formAdsSelected;

    setMediasList(main);

    const prepareFor = prepareObj(main);

    const currentStatus = prepareFor({ status: false, priority: 0 });

    if (myMedias.length && as === 'edit') {
      const newListStatus = includeMedias(myMedias, currentStatus);
      const guide = includeSubjectAreas(newListStatus);
      setSaSelected(guide);
    }

    setStatusPriority(currentStatus);
  };

  useEffect(() => {
    const { offering, profession: professionId } = formAdsSelected;
    if (offering && offering.length && professionId) {
      const [firstOffering] = offering;

      if (firstOffering) {
        const { course } = firstOffering;
        const findByProfession = ({ profession }) => profession.id === professionId;

        const saByProfessions = course.components.find(findByProfession);

        if (saByProfessions) {
          const {
            profession: { subjectAreas },
          } = saByProfessions;
          setSa(subjectAreas);
        }
      }
    }
  }, [formAdsSelected]);

  useEffect(() => {
    setSaSelected({});
    initialData();
  }, [formAdsSelected.profession, formAdsSelected.course]);

  useEffect(() => {
    initialData();
  }, [as]);

  useEffect(() => {
    if (Object.keys(saSelected).length) {
      const mySubjectAreas = Object.entries(saSelected);
      const newStatus = { ...statusPriority };

      mySubjectAreas.forEach(([code, arr]) => {
        newStatus[code].refs = arr;
      });

      setStatusPriority({ ...newStatus });
    }
  }, [saSelected]);

  useEffect(() => {
    const medias = Object.entries(statusPriority);
    const myMedias = medias.reduce((acc, [, data]) => {
      let constructMediasSa = [];
      const { code, refs, findableWhenNoSubjectArea, priority, status, dateAdded } = data;
      if (status) {
        if (findableWhenNoSubjectArea) {
          constructMediasSa = [
            {
              code,
              priority,
              subjectAreas: code === GLOBAL_MEDIAS_SA.includes(code) ? sa.map(({ xref }) => xref) : [],
              findableWhenNoSubjectArea,
              dateAdded: dateAdded ? new Date(dateAdded) : new Date(),
            },
          ];
        } else if (!findableWhenNoSubjectArea && refs.length) {
          constructMediasSa = refs.map(({ xref, priority, dateAdded }) => {
            return {
              code,
              subjectAreas: [xref],
              priority,
              findableWhenNoSubjectArea,
              dateAdded: dateAdded ? new Date(dateAdded) : new Date(),
            };
          });
        }

        return [...acc, ...constructMediasSa];
      }
      return [...acc];
    }, []);

    dispatch({
      type: 'SET_FIELD',
      field: 'medias',
      value: myMedias,
    });
  }, [statusPriority]);
  const spotlightCode = 'Spotlight CE';
  return (
    <React.Fragment>
      {!viewSpotlight && (
        <Alert color="warning">
          <p style={{ padding: '5px 0', margin: 0 }}>The Spotlight section is disabled for this profession.</p>
        </Alert>
      )}
      <div className="medias-wrapper">
        {mediasList.map((item, index) => {
          return (
            <React.Fragment key={`RF_${index}`}>
              {(item.code !== spotlightCode || (item.code === spotlightCode && viewSpotlight)) && (
                <MediaItem
                  key={`${item._id}_${index}`}
                  index={index}
                  item={item}
                  statusPriority={statusPriority}
                  setStatusPriority={setStatusPriority}
                  codeTyping={codeTyping}
                  setCodeTyping={setCodeTyping}
                  sa={sa}
                  setSaSelected={setSaSelected}
                  saSelected={saSelected}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

Medias.propTypes = {
  viewSpotlight: PropTypes.any,
};

export default Medias;
