const buildFieldsMedias = (state, { data }) => {
  return { ...state, ...data };
};

const setValueOn = (state, { key, code, value }) => {
  return { ...state, [code]: { ...state[code], [key]: value } };
};

export default function (state, action) {
  switch (action.type) {
    case 'BUILD_FIELDS':
      return buildFieldsMedias(state, action);
    case 'SET_VALUE_ON':
      return setValueOn(state, action);
    default:
      return state;
  }
}
