import React, { useEffect } from 'react';
import { debounce } from 'lodash';

import { useStateContext } from './../../store/context';
import { paginate } from './../../api/http/ads';

import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';

import ModalAds from './../../components/ModalAds';
import ListCards from './../../components/ListCards';

import Toast from 'emerald-ui/lib/Toast';

import './styles.css';

function Home() {
  const [
    {
      ads: { list, limit, totalItems, pageIndex, filter },
      modalAds: { show, as },
      toastAds,
    },
    dispatch,
  ] = useStateContext();

  function attachState(data) {
    if (data) {
      dispatch({
        type: 'LOAD_ADS_DATA',
        data: data.items,
      });

      dispatch({
        type: 'LOAD_PAGINATED_DATA',
        pageIndex: data.pageIndex,
        limit: data.pageSize,
        totalItems: data.totalItems,
      });
    }
  }

  useEffect(() => {
    (async () => {
      const data = await paginate(pageIndex, limit, filter);
      attachState(data);
    })();
  }, [pageIndex, limit]);

  useEffect(() => {
    (async () => {
      const data = await paginate(pageIndex, limit, filter);
      attachState(data);
      dispatch({
        type: 'RESET_OFFSET',
      });
    })();
  }, [filter, limit]);

  function onFilter(data) {
    dispatch({
      type: 'FILTER_BY',
      data,
    });
    dispatch({
      type: 'RESET_INDEX',
    });
  }

  const onSearch = debounce((value) => {
    if (!value) {
      dispatch({
        type: 'FILTER_BY',
        data: {
          key: 'title',
          value: null,
        },
      });
      dispatch({
        type: 'FILTER_BY',
        data: {
          key: 'course',
          value: null,
        },
      });
    }
    const regIfCourse = new RegExp(/^20-/, 'gi');

    const codeCourseVal = regIfCourse.test(value);

    if (codeCourseVal) {
      const { 1: courseId } = value.split('-');

      if (courseId) {
        dispatch({
          type: 'FILTER_BY',
          data: {
            key: 'course',
            value: parseInt(courseId, 10) || null,
          },
        });
      }
    } else if (!codeCourseVal && Number.isNaN(parseInt(value))) {
      dispatch({
        type: 'FILTER_BY',
        data: {
          key: 'title',
          value: value || null,
        },
      });
    }
  }, 700);

  const openModal =
    (as = 'add', data = {}) =>
    (event) => {
      event.preventDefault();
      dispatch({
        type: 'OPEN_MODAL',
        data,
        as,
      });
    };

  function closeModal() {
    dispatch({
      type: 'CLOSE_MODAL',
    });

    dispatch({
      type: 'RESET_FORM_MODAL',
    });

    dispatch({
      type: 'RESET_FORM',
    });

    dispatch({
      type: 'DISABLE_VALIDATE',
    });
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <ListCards
            data={list}
            limit={limit}
            pageIndex={pageIndex}
            totalItems={totalItems}
            onChangeFiltered={onFilter}
            onSearchChange={(e) => void onSearch(e.target.value)}
            openModal={openModal}
          />
        </Col>
      </Row>
      <ModalAds show={show} onHide={closeModal} as={as} />
      <Toast
        message={toastAds.message}
        visible={toastAds.show}
        onTimeout={() =>
          dispatch({
            type: 'CLOSE_TOAST',
          })
        }
      />
    </Container>
  );
}

export default Home;
