import React from 'react';
import PropTypes from 'prop-types';

import { useStateContext } from './../../store/context';

import Panel from 'emerald-ui/lib/Panel';
import Card from 'emerald-ui/lib/Card';
import Pager from 'emerald-ui/lib/Pager';
import Toolbar from 'emerald-ui/lib/Toolbar';
import SearchForm from 'emerald-ui/lib/SearchForm';
import PopFilter from './../../components/PopFilter';
import Label from 'emerald-ui/lib/Label';
import Clampy from '@clampy-js/react-clampy';

import IconActionDropdown from './../common/IconActionDropdown';

import { simpleFormat } from './../../utils/dateFormat';

import { STATUS_INACTIVE, STATUS_DISABLE } from './../../config';

import styleListCard from './ListCard.module.css';

import './styles.css';

import GlobalFab from '../FabGlobal';

const CompCard = ({ ad, openModal }) => {
  const dateAdded = simpleFormat(ad);
  const courseId = ad.courseId;
  const providerId = ad.providerId;

  return (
    <div className={ad.status === STATUS_INACTIVE ? STATUS_DISABLE : ''}>
      <Card className={ad.status === STATUS_INACTIVE ? STATUS_DISABLE : ''} onClick={openModal('edit', ad)}>
        <div className="card-body">
          <div className="card-content">
            <div className="card-logo">
              <img src={ad.img} alt={ad.tagline} />
            </div>
            <div className="card-text">
              <div className="header-text">
                <h2 className={styleListCard.titleCard}>{ad.title.toLowerCase()}</h2>
                <div className={styleListCard.spaceLabelCourse}>
                  <Label className="alter-label" color="info">
                    {ad.adType}
                  </Label>
                </div>
              </div>
              <div className="card-description-wide">
                <div className="description-ids">
                  {courseId ? <span className="details">{`20-${courseId}`}</span> : ''}
                  {providerId ? <span className="details">{`50-${providerId}`}</span> : ''}
                </div>

                {dateAdded ? (
                  <div>
                    <span>Added: </span>
                    <span className="details">{dateAdded}</span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <Clampy clampSize="3">{ad.description}</Clampy>
              <div className="card-status">
                {ad.rolling && (
                  <div>
                    <Label className="alter-label" color="default">
                      Rolling
                    </Label>
                  </div>
                )}
              </div>
            </div>
            <IconActionDropdown ad={ad} className="card-tools" />
          </div>
        </div>
      </Card>
    </div>
  );
};

CompCard.propTypes = {
  ad: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    srcImg: PropTypes.string,
    imgAlt: PropTypes.string,
    courseId: PropTypes.any,
    providerId: PropTypes.any,
    status: PropTypes.any,
    img: PropTypes.any,
    tagline: PropTypes.any,
    adType: PropTypes.any,
    rolling: PropTypes.any,
  }).isRequired,
  openModal: PropTypes.any,
};

function ListCard({ data, onChangeFiltered, onSearchChange, limit, totalItems, openModal }) {
  const [
    {
      ads: { offset },
    },
    dispatch,
  ] = useStateContext();

  const onChangePage = (action, offset) => {
    dispatch({
      type: 'SET_OFFSET',
      offset,
    });

    dispatch({
      type: `${action.toUpperCase()}_PAGINATION`,
    });
  };

  const onLimitChange = (limit) => {
    dispatch({
      type: 'RESET_OFFSET',
    });

    dispatch({
      type: 'CHANGE_LIMIT',
      data: limit,
    });
  };

  const openModalEdit =
    (as = 'add', ad, data = {}) =>
    (event) => {
      event.stopPropagation();
      if (as === 'edit') {
        dispatch({
          type: 'FORMAT_EXT_DATA',
          data: ad,
        });
      }
      dispatch({
        type: 'OPEN_MODAL',
        data,
        as,
      });
    };

  return (
    <Panel>
      <Panel.Heading>
        <Toolbar>
          <SearchForm
            clearable
            onSubmit={(e) => void e.preventDefault()}
            onChange={onSearchChange}
            onClear={onSearchChange}
          />
          <PopFilter onChangeFilter={onChangeFiltered} />
          <GlobalFab openModal={openModal} />
        </Toolbar>
      </Panel.Heading>
      <Panel.Body className={styleListCard.listContainer}>
        {data.map((item, index) => (
          <CompCard key={index} ad={item} openModal={openModalEdit} />
        ))}
      </Panel.Body>
      <Panel.Footer>
        <Pager
          onPageChange={onChangePage}
          onLimitChange={onLimitChange}
          offset={offset}
          limit={limit}
          total={totalItems}
          style={{ width: '100%' }}
        />
      </Panel.Footer>
    </Panel>
  );
}

ListCard.propTypes = {
  data: PropTypes.any,
  onChangeFiltered: PropTypes.any,
  onSearchChange: PropTypes.any,
  limit: PropTypes.any,
  totalItems: PropTypes.any,
  openModal: PropTypes.any,
};

export default ListCard;
