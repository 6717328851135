import axios from 'axios';
import config from './../../config';

const { AUTH_ACCOUNT_API } = config;

export const token = async (token) => {
  const { data } = await axios.get(AUTH_ACCOUNT_API, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
