import { gql } from 'apollo-boost';

export const GET_SUBJECTAREA_NAME = gql`
  query getSubjectareaByProfession($profession: Int) {
    professions(id: $profession) {
      subjectarea {
        id_subjectarea_master
        subjectarea_master {
          basic_data {
            name
          }
        }
      }
    }
  }
`;

export const GET_SUBJECTAREA_BY_ID = gql`
  query getSubjectarea($sa: Int) {
    subjectareas(id: $sa) {
      id
      basic_data {
        name
      }
    }
  }
`;

export const GET_SUBJECTAREAS = gql`
  query getSubjectareas {
    subjectareas {
      id
      basic_data {
        name
      }
    }
  }
`;
