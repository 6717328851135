import React, { useState, useEffect } from 'react';

import { GET_BOARDS_NAME } from './../../api/graphql/boards';
import { GET_PROFESSION_NAME } from './../../api/graphql/professions';
import { GET_STATE_ACTIVE } from './../../api/graphql/states';
import { GET_SUBJECTAREA_NAME } from './../../api/graphql/subjectarea';

import { useStateContext, useGqlContext } from './../../store/context';

import { getMedias } from './../../api/http/ads';

import SingleSelect from 'emerald-ui/lib/SingleSelect';
import OverlayTrigger from 'emerald-ui/lib/OverlayTrigger';
import Popover from 'emerald-ui/lib/Popover';
import IconButton from 'emerald-ui/lib/IconButton';

import capitalize from '../../utils/capitalize';
import { formatDataByModel } from '../../utils/format/states';

import stylePopFilter from './PopFilter.module.css';
import PropTypes from 'prop-types';

async function useReqGql(client, query, variables = {}) {
  const { data } = await client.query({ query, variables });

  return data;
}

const sortByName = ({ basic_data: { name: nameA } }, { basic_data: { name: nameB } }) => {
  return nameA.localeCompare(nameB);
};

const PopFilter = ({ onChangeFilter }) => {
  const [
    {
      ads: { filter },
    },
    dispatch,
  ] = useStateContext();

  const { client } = useGqlContext();

  const filter_next = {
    states: {
      input: GET_BOARDS_NAME,
      index: 'boards',
    },
    boards: {
      input: GET_PROFESSION_NAME,
      index: 'professions',
    },
    professions: {
      input: GET_SUBJECTAREA_NAME,
      index: 'subjectareas',
    },
  };

  // TODO: Define object to set fields params
  const [external_filter_fields, setFieldExternal] = useState([
    {
      label: 'states',
      options: [],
    },
    {
      label: 'boards',
      options: [],
    },
    {
      label: 'professions',
      options: [],
    },
  ]);

  // TODO: Define object to set fields params
  const [filter_fields, setFilterFields] = useState([
    {
      label: 'status',
      options: [
        {
          text: 'ACTIVE',
          value: 'active',
        },
        {
          text: 'INACTIVE',
          value: 'inactive',
        },
      ],
    },
    {
      label: 'medias',
      options: [],
    },
  ]);

  const prepareInitState = (key, res, wrapper, initialData) => {
    const currentExt = [...wrapper];

    let data = res;

    if (key === 'states') {
      data = data.sort(sortByName);
    }

    if (currentExt.length > 0) {
      const fillNewFilter = currentExt.map((item) => {
        if (item.label === key) {
          return {
            label: item.label,
            options: [initialData, ...data],
          };
        }
        return item;
      });

      return fillNewFilter;
    }
  };

  const getStatesReq = async () => {
    const { boards: stateBoards } = await useReqGql(client, GET_STATE_ACTIVE);

    const { states } = formatDataByModel({
      field: 'states',
      data: stateBoards,
    });

    const mappedStates = prepareInitState('states', states, external_filter_fields, {
      id: null,
      basic_data: {
        name: 'ALL',
      },
    });

    return mappedStates;
  };

  const getMediasReq = async () => {
    let medias = await getMedias();

    if (medias.length) {
      dispatch({
        type: 'SET_MEDIAS',
        data: medias,
      });

      medias = medias.map(({ code, findableWhenNoSubjectArea }) => ({
        text: code,
        value: JSON.stringify({ code, findableWhenNoSubjectArea }),
        findableWhenNoSubjectArea,
      }));

      const mappedMedias = prepareInitState('medias', medias, filter_fields, {
        value: null,
        text: 'ALL',
      });

      return mappedMedias;
    }
  };

  useEffect(() => {
    (async () => {
      // Get states from Settings Services
      const states = await getStatesReq();

      // Get medias from Api Ads
      const medias = await getMediasReq();

      setFieldExternal([...states]);
      setFilterFields([...medias]);
    })();
  }, []);

  const serializeValue = (key) => (value) => {
    onChangeFilter({ key, value });
  };

  const serializeExtValue = (key) => async (value) => {
    const currentExt = [...external_filter_fields];

    switch (key) {
      case 'states': {
        const excl = ['boards', 'professions', 'subjectareas'];
        const alt = currentExt.map((item) => {
          if (excl.includes(item.label)) {
            return {
              ...item,
              options: [],
            };
          }
          return item;
        });
        setFieldExternal(alt);
        onChangeFilter({ key: 'boards', value: null });
        onChangeFilter({ key: 'professions', value: null });
        onChangeFilter({ key: 'subjectareas', value: null });

        break;
      }
      case 'boards': {
        const exclb = ['professions', 'subjectareas'];
        const altb = currentExt.map((item) => {
          if (exclb.includes(item.label)) {
            return {
              ...item,
              options: [],
            };
          }
          return item;
        });
        setFieldExternal(altb);
        onChangeFilter({ key: 'professions', value: null });
        onChangeFilter({ key: 'subjectareas', value: null });
        break;
      }

      default:
        break;
    }

    if (filter_next[key]) {
      const index = filter_next[key].index;
      const singularKey = key.substring(0, key.length - 1);
      let data = {};

      data = await useReqGql(client, filter_next[key].input, {
        [singularKey]: value,
      });

      const newParse = currentExt.map((item) => {
        if (item.label === index) {
          const aux = data[index].sort(sortByName);

          return {
            label: item.label,
            options: [
              {
                id: null,
                basic_data: {
                  name: 'ALL',
                },
              },
              ...aux,
            ],
          };
        }
        return item;
      });

      setFieldExternal(newParse);
    }
    onChangeFilter({ key, value });
  };

  const FilterFields = ({ item: { label, options } }) => {
    return (
      <SingleSelect label={capitalize(label)} onSelect={serializeValue(label)} className={stylePopFilter.selectWrapper}>
        {options.map((ops, index) => {
          const alterOps = ops;

          if (label === 'medias') {
            alterOps.text += alterOps.text === 'Featured Courses' && !alterOps.findableWhenNoSubjectArea ? ' SA' : '';
          }
          return (
            <option
              key={index}
              value={alterOps.value}
              captionText={alterOps.value}
              selected={filter[label] === alterOps.value}
            >
              {alterOps.text}
            </option>
          );
        })}
      </SingleSelect>
    );
  };

  FilterFields.propTypes = {
    item: PropTypes.any,
  };

  const ExternalFilterFields = ({ item: { label, options } }) => {
    return (
      <SingleSelect
        label={capitalize(label)}
        onSelect={serializeExtValue(label)}
        className={stylePopFilter.selectWrapper}
      >
        {options.map(({ id, basic_data: { name } }, index) => (
          <option key={index} value={id} selected={filter[label] === id} captionText={id}>
            {name}
          </option>
        ))}
      </SingleSelect>
    );
  };

  ExternalFilterFields.propTypes = {
    item: PropTypes.any,
  };

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover className={stylePopFilter.popoverContent}>
            <div className={stylePopFilter.alignPopFilter}>
              {filter_fields && filter_fields.map((item, index) => <FilterFields key={index} item={item} />)}
              {external_filter_fields.map((item, index) => (
                <ExternalFilterFields key={index} item={item} />
              ))}
            </div>
          </Popover>
        }
      >
        <IconButton icon="filter_list" title="Filters" />
      </OverlayTrigger>
    </React.Fragment>
  );
};

PopFilter.propTypes = {
  onChangeFilter: PropTypes.any,
};

export default PopFilter;
