export const formatDataByModel = ({ field, data }) => {
  const res = data
    .map(({ basic_data: { state } }) => ({ ...state }))
    .reduce((acc, state) => {
      if (acc.length) {
        const existThisState = acc.some(({ id }) => id === state.id);

        if (existThisState) {
          return acc;
        }
      }

      return [...acc, state];
    }, []);

  return { [field]: res };
};
