export default function (state, action) {
  switch (action.type) {
    case 'VALIDATE':
      return {
        ...state,
        errors: true,
      };
    case 'DISABLE_VALIDATE':
      return {
        ...state,
        errors: false,
      };
    default:
      return state;
  }
}
